import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import styles from './styles.module.css'

export const query = graphql`
  query AllBlogs {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      limit: 1000
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt
          frontmatter {
            path
            date
            title
          }
        }
      }
    }
  }
`

const IndexPage = ({data}) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <>
      <Layout>
        <SEO title="Blog" />
        <div className={styles.container}>
          {posts
            .filter(post => post.node.fileAbsolutePath.includes('/blog/'))
            .map(post => (
              <>
                <h2 className={styles.postLink}>
                  <Link to={post.node.frontmatter.path}>
                    {post.node.frontmatter.title}
                  </Link>{' '}
                  <br />
                  <span className={styles.date}>
                    {post.node.frontmatter.date}
                  </span>
                </h2>
                <p className={styles.excerpt}>{post.node.excerpt}</p>
              </>
            ))}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
